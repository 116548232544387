<template>
  <main>


    <page-header :title="currentGroup" pre-title="Overview" :loading="fetchingGroupList">
	
		</page-header>

    <div class="container-fluid">


      <div class="row mt-5">
        <div class="col-12">
          <div class='w-full flex justify-between items-center'>
            <button @click="goBack" class="btn btn-light mb-4 btn-sm">
              Go back
            </button>

            <div class='gap-4 flex justify-between items-center'>
              <button class="btn btn-outline border border-primary mb-4 btn-sm" @click='() => { groupName = currentGroup; is_group_modal = true }'>
                change group name
              </button>
              <button class="btn btn-primary mb-4 btn-sm" @click='is_staff_modal = true'>
                add staff
              </button>
            </div>



          </div>
          <div class="card">
            <div class="card-header justify-content-center">
              <div class="input-group input-group-flush d-flex flex-row-reverse">
                <input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search" />
                <span class="input-group-text border-0">
                  <i class="fe fe-search" @click.prevent="fetchGroupList(true)"></i>
                </span>
              </div>
            </div>

            <b-table striped hover selectable responsive show-empty :items="groupList" :fields="fields" :current-page="currentPage" :busy="fetchingGroupList">
              <template #table-busy>
                <div class="d-flex flex-column justify-content-center align-items-center">
                  <div class="spinner-border" role="status"></div>
                  <p class="text-center mt-2"><strong>Loading...</strong></p>
                </div>
              </template>
              <template #empty>
                <p class="text-center text-secondary">
                  {{
                    search
                    ? `No records found for search value: "${search}"`
                    : 'No records available'
                  }}
                </p>
              </template>
              <template #cell(serialNumber)="data">
                <p>
                  {{ data.index + 1 }}
                </p>
              </template>
              <template #cell(name)="data">
                <span v-if="data?.item?.staff?.fname || data?.item?.staff?.lname">{{
                  `${data?.item?.staff?.fname} ${data?.item?.staff?.lname}`
                }}</span> </template>?

              <template #cell(email)="data">
                {{ data?.item?.staff?.email ?? 'N/A' }}
              </template>
              <template #cell(home_address)="data">
                {{ data?.item?.staff?.home_address ?? 'N/A' }}
              </template>
              <template #cell(nearest_bus_stop)="data">
                {{ data?.item?.staff?.nearest_bus_stop ?? 'N/A' }}
              </template>
              <template #cell(office_branch)="data">
                {{ data?.item?.staff?.office_branch ?? 'N/A' }}
              </template>
              <template #cell(work_shift)="data">
                {{ data?.item?.staff?.work_shift ?? 'N/A' }}
              </template>
              <template #cell(work_days)="data">
                <p v-if="data.item.work_days">
                  <span class="sh-page-link badge text-dark bg-light mx-1 p-2" v-for="(day, index) in data.item.work_days" :key="index">{{ day }}</span>
                </p>
                <span v-else>N/A</span>
              </template>
              <template #cell(id)="data">

                <span style="color: red; font-size: 20px" class="fa fa-trash-o" @click='deleteMember(data.item)'></span>


              </template>
            </b-table>

            <div class="card-footer" v-if="totalRecords">
              <div class="row align-items-center">
                <div class="col">
                  <!-- <span class="text-dark font-weight-bold">{{
                    tablePageText
                  }}</span> -->
                </div>
                <div class="col-auto">
                  <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BS_modal :show='is_group_modal' title='Edit Group Name' @close='is_group_modal = false' modalId='is_group_modal'>
      <div class="form-group mb-3">
        <label class="form-label">Group Name</label>
        <input class="form-control" v-model='groupName' />
      </div>
      <b-button :disabled="createLoading || groupName.length === 0" variant="primary" class='w-full mt-4' @click="changeGroupName()">
        {{ createLoading ? 'loading...' : 'Update' }}
      </b-button>
    </BS_modal>

    <BS_modal :show='is_staff_modal' title='Add Members' @close='is_staff_modal = false' modalId='is_staff_modal'>
      <div class="form-group mb-3">
        <div class="">
          <label for="">Select staff</label>
          <multiselect v-model="users" id="select_users_input" :custom-label="({ fname, lname }) => `${fname} ${lname}`" track-by="id" placeholder="Type to search" open-direction="bottom" :options="usersList" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="true"
            :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="10" :show-no-results="false" :hide-selected="false">
            <template slot="clear">
              <div class="multiselect__clear" v-if="users?.length" @mousedown.prevent.stop="users = []">
              </div>
            </template><span slot="noResult">Oops! No users found. Consider changing the search
              query.</span>
          </multiselect>
        </div>
      </div>

      <b-button :disabled="createLoading || users.length === 0" variant="primary" class='w-full mt-4' @click="addMembers()">
        {{ createLoading ? 'loading...' : 'add' }}
      </b-button>
    </BS_modal>
  </main>
</template>

<!-- <script setup>
</script> -->



<script setup>
import PageHeader from '@/components/layout/PageHeader'
import { ref, reactive, watch, onMounted, computed } from 'vue'
// import { userHasPermission, DoNotShowOnProd } from '@/composables/core/permissions'
import { axiosInstance } from '@/plugins/axios'
import Multiselect from 'vue-multiselect'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import Swal from 'sweetalert2'
import { useRoute, useRouter } from 'vue-router/composables'

const is_group_modal = ref(false)
const is_staff_modal = ref(false)
const isLoading = ref(false)
const createLoading = ref(false)
const groupName = ref('')
const users = ref([])
const usersList = ref([])

const route = useRoute()
const router = useRouter()

const deleteMember = async (data) =>
{
  await Swal.fire({
    icon: 'question',
    title: 'Please Confirm',
    text: 'Are you sure you want to remove this staff?',
    showConfirmButton: true,
    showCancelButton: true,
    preConfirm: () =>
    {
      return axiosInstance
        .delete(`https://api.test.shuttlers.africa/v1/corporate-groups/${route.params.groupId}/members/${data?.staff?.id}`)
        .catch((error) =>
        {
          const msg =
            error.response && error.response.data
              ? error.response.data.message
              : 'An error occured, please try again.'

          Swal.showValidationMessage(msg)
        })
        .finally(() =>
        {
          fetchGroupList(true)
        })
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) =>
  {
    if (result.isConfirmed) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Staff was successfully removed',
        showCloseButton: true
      })
    }
  })
}

const changeGroupName = () =>
{
  createLoading.value = true
  axiosInstance.patch(`/v1/corporates/${route.params.companyId}/groups/${route.params.groupId}`, { name: groupName.value })
    .then((res) =>
    {
      fetchGroupList(true)
      Swal.fire({
        icon: 'success',
        title: 'Group created',
        text: 'Group created successfully',
        showCloseButton: true
      }).then(() =>
      {
        is_group_modal.value = false
        createLoading.value = false
        groupName.value = ''
        users.value = []
      })

    })
    .catch((e) =>
    {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while creating group',
      })
      createLoading.value = false
      is_group_modal.value = false
      groupName.value = ''
      users.value = []
    })
}
const addMembers = () =>
{
  createLoading.value = true
  axiosInstance.post(`/v1/corporate-groups/${route.params.groupId}/members`, { role: 'member', staff_ids: users.value.map((eachUser) => eachUser.id) })
    .then((res) =>
    {
      fetchGroupList(true)
      Swal.fire({
        icon: 'success',
        title: 'Members added',
        text: 'Members added successfully',
        showCloseButton: true
      }).then(() =>
      {

        is_staff_modal.value = false
        createLoading.value = false
        users.value = []
      })

    })
    .catch((e) =>
    {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while adding members',
      })
      createLoading.value = false
      is_staff_modal.value = false
      users.value = []
    })
}


const fetchUsers = () =>
{
  axiosInstance
    .get(`/v1/corporates/${route.params.companyId}/staff`)
    .then((response) =>
    {
      usersList.value = response.data.data
    })
    .catch((e) =>
    {
      usersList.value = []
    })
}
fetchUsers()

const fields = [
  {
    key: 'serialNumber',
    label: 'S/N',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'home_address',
    label: 'Home Address',
  },
  {
    key: 'nearest_bus_stop',
    label: 'Nearest bus stop',
  },
  {
    key: 'office_branch',
    label: 'Office branch',
  },
  {
    key: 'work_shift',
    label: 'Work shift',
  },
  {
    key: 'work_days',
    label: 'Work days',
  },
  {
    key: 'id',
    label: '',
  },
]

const pageSize = ref(20)
const currentPage = ref(1)
const totalRecords = ref(0)
const groupList = ref([])
const fetchingGroupList = ref(true)
const currentGroup = ref('')
const search = ref('')
const debounce = ref(null)
const filter = reactive({
  range: [],
})
const statusFilter = ref('active')

// const filteredPartners = computed(() =>
// {
//   const searchValue = search.value.toLowerCase()

//   return groupList.value.filter((eachGroup) =>
//   {
//     return (
//       eachGroup.fname.toLowerCase().includes(searchValue) ||
//       eachGroup.lname.toLowerCase().includes(searchValue) ||
//       eachGroup.email.toLowerCase().includes(searchValue)
//     )
//   })
// })

const goBack = () =>
{
  router.go(-1)
}

const fetchGroupList = (reset = false) =>
{
  if (reset) {
    currentPage.value = 1
  }

  fetchingGroupList.value = true

  const url = `/v1/corporate-groups/${route.params.groupId}/members?related=corporateGroup,staff`
  const request = search.value
    ? axios.get(url, {
      search: search.value || '',
    })
    : axios.get(url)
  request
    .then((res) =>
    {
      groupList.value = res.data.data
      const groupArray = groupList.value.map(
        (eachContent) => eachContent.corporateGroup.name
      )
      const unique_array = [...new Set(groupArray)]
      currentGroup.value = unique_array[0]
      totalRecords.value = res.data?.metadata?.total
      return groupList.value
    })
    .finally(() => (fetchingGroupList.value = false))
}

watch(search, () =>
{
  clearTimeout(debounce.value)
  debounce.value = setTimeout(() =>
  {
    fetchGroupList(true)
  }, 600)
})

watch(currentPage, () =>
{
  fetchGroupList()
})

watch(statusFilter, () =>
{
  fetchGroupList(true)
})

onMounted(() =>
{
  fetchGroupList()
})

</script>

<style scoped lang="scss">
@use 'src/assets/scss/b-table';
</style>
